import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Link, navigate } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import axios from "axios";

const getPropurl = async (handler, crmid) => {
  let result = [];
  //instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
  var data = JSON.stringify({
    query:
      "query($crm_id:[String]) { properties(where: { crm_id: $crm_id}) { id, slug, search_type, department } }",
    variables: { crm_id: [crmid] },
  });
  var config = {
    method: "post",
    url: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
    data: data,
  };
  const res = await axios(config);
  result[0] = JSON.stringify(res.data);
  handler(result); //update state var
};

const Page404 = (props) => {
  const [showtemplate, setShowtemplate] = React.useState(false);
  const [propdata, setPropdata] = React.useState("");

  useEffect(() => {
    // We're doing this as a fallback, in case the build fails or isn't triggered.
    let regex = /([0-9]{12})/g; // You can modify the preg as per project feed
    if (props?.location?.pathname) {
      let crm_id = (props?.location?.pathname).match(regex);
      // Make sure state value is empty and we have proper URL to proceed
      if (typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !propdata) {
        getPropurl(setPropdata, crm_id[0]); // Call the function to fetch data from Strapi
      } else if (propdata) {
        let property_data = JSON.parse(propdata);
        let property_arr = property_data.data.properties;
        if (property_arr.length > 0) {
          let property = property_arr[0];
          // Set the redirection logic, same mentioned in the gatsby-node for _redirects.txt ie., crm_id to details page
          if (property.id) {
            if (property.search_type == "sales") {
              navigate(`/property-for-sale/${property.slug}-${property.id}`, { replace: true });
            } else if (property.search_type == "lettings") {
              navigate(`/property-to-rent/${property.slug}-${property.id}`, { replace: true });
            }
          }
        } else {
          setShowtemplate(true); // Show 404 page template
        }
      } else {
        setShowtemplate(true); // Show 404 page template
      }
    }
  }, [propdata]);

  return showtemplate ? (
    <React.Fragment>
      <Header />
      <section className="errorpage">
        <Container>
          <Row className="d-flex justify-content-center bd-highlight mb-3 text-center">
            <Col md="9">
              <h1>We’re sorry, the page you were looking for cannot be found.</h1>
              <p>
                This maybe because it has been removed, we’ve changed its name or it is temporarily
                unavailable.
              </p>
              <div class="btn-wrapper">
                <Link to="/property/for-sale/in-london" class="btn btn-fill-primary">
                  Find a Home
                </Link>
                <Link to="/" class="btn btn-fill-primary">
                  Homepage
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </React.Fragment>
  ) : (
    <LoadingScreen />
  );
};
export default Page404;
