import React from "react";
import BrandLogo from "../../images/icons/logo.svg";

const LoadingScreen = () => {
  return (
    <section className={"loader-wrapper"}>
      <div id="loader-wrapper">
        <div id="loader" className="new-loader">
          <img className="logo-white loader-logo" src={BrandLogo} alt="logo" />
        </div>
      </div>
    </section>
  );
};

export default LoadingScreen;
